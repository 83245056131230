.dashboard--table {
    --color-primary: blue;
    border-spacing: 0;
    border-collapse: collapse;
}
.dashboard--table :is(th, td) {
    padding: 0.5em 1em;
    border: solid thin #ccc
}
.dashboard--column__status {
    display: flex;
    justify-content: space-between;
}
.dashboard--column__status-text {
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 0.5em;
    text-align: center;
    margin-right: 1em;
}
.dashboard--cost-breakdown-container {
    display: grid;
    grid-template-columns: 1em 1fr auto;
}
.dashboard--cost-breakdown-container > * {
    padding-left: 1em;
}
.dashboard--total__env {
    float: right;
    font-weight: bold;
}
.dashboard--row {
    display: grid;
    grid-template-columns: 20ch 24ch 1fr;
}
.dashboard--details-summary {
    display: inline-flex;
    gap: 2em;
    justify-content: space-between;
}
